<template>
    <mapbox-map
        :access-token="accessToken"
        :map-style="mapStyle"
        :center="coordinates"
        :attributionControl="false"
        :zoom="zoom"
        @mb-created="onMapLoaded"
        class="shadow border"
    >
        <mapbox-navigation-control
            position="top-right"
        />

        <mapbox-image
            :src="host + '/wp-content/themes/98/img/map-pin-RESI-active.png'"
            id="hut" />

        <mapbox-cluster 
            v-if="dataReady"
            :data="pins" 
            :clusterMaxZoom="12"
            :clustersPaint="{
                'circle-color': '#C41230',
                'circle-stroke-color': '#FFFFFF',
                'circle-stroke-width': 4,
                'circle-radius': 30
            }"
            :clusterCountPaint="{
                'text-color': '#FFF'
            }"
            :clusterCountLayout="{
                'text-field': [ 'get', 'point_count_abbreviated' ]
            }"
            unclusteredPointLayerType="symbol"
            :unclusteredPointLayout="{
                'text-field': [ 'get', 'price' ],
                'text-size': 12,
                'icon-image': 'hut',
                'icon-anchor': 'bottom',
                'text-offset': [0,-1.5]
            }"
            :unclusteredPointPaint="{
                'text-color': '#000', 
                'text-halo-color': '#FFF',
                'text-halo-width': 3,
            }"
            @mb-feature-click="propertyClick"
        />
    </mapbox-map>
</template>

<script>
import { Mapbox } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxMap from "@studiometa/vue-mapbox-gl/dist/components/MapboxMap";
import MapboxNavigationControl from "@studiometa/vue-mapbox-gl/dist/components/MapboxNavigationControl";
import MapboxMarker from "@studiometa/vue-mapbox-gl/dist/components/MapboxMarker";
import MapboxCluster from "@studiometa/vue-mapbox-gl/dist/components/MapboxCluster";
import MapboxImage from "@studiometa/vue-mapbox-gl/dist/components/MapboxImage"

export default {
    components: {
        "mapbox-map": MapboxMap,
        "mapbox-navigation-control": MapboxNavigationControl,
        "mapbox-cluster": MapboxCluster,
        "mapbox-marker": MapboxMarker,
        "mapbox-image": MapboxImage
    },

    props: {
        lat: {
            type: Number,
            default: 0,
        },
        long: {
            type: Number,
            default: 0,
        },
        zoom: {
            type: Number,
            default: 10,
        },
        pins: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        dataReady() {
            return this.pins.features.length > 0;
        },
    },

    data() {
        return {
            accessToken:
                "pk.eyJ1Ijoia2VyaWdhbiIsImEiOiJjbDV3bXIxdXEwN3I2M2NyZ3I0Y2Z2bTA5In0.OtvWe8iHxzLWXantpQV0EA", // your access token. Needed if you using Mapbox maps
            mapStyle: "mapbox://styles/kerigan/ckgmth6dl1jjc19pdxj6rma2l", // your map style
            coordinates: [this.lat, this.long],
            map: null,
            host: ''
        };
    },

    created() {
        this.host = window.location.origin
    },

    methods: {
        onMapLoaded(mapInstance) {
            this.map = mapInstance;
            this.$emit("map loaded");
        },
        propertyClick(payload) {
            this.$emit("propertyClicked", payload);
            // console.log(payload);
        },
    },
};
</script>
<style lang="scss" scoped >
.mapboxgl-map {
    min-height: 400px;
    height: 100%;
    width: 100%;
    min-height: 220px;

    @media screen and (min-width: 768px) {
        height: 350px;
    }

    @media screen and (min-width: 1025px) {
        height: 600px;
    }

    @media screen and (min-width: 1400px) {
        height: 100%;
        min-height: 600px;
    }

    .mapboxgl-canvas-container,
    .mapboxgl-canvas {
        height: 100% !important;
        width: 100% !important;
    }
}
</style>