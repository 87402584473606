<template>
  <on-click-outside :do="closeAndSelect" >
    <div class="search-select border-accent">
      <div class="d-flex" v-show="!isOpen">
        <slot />
        <button
          ref="button"
          @click="open"
          @keyup.enter="open"
          aria-label="press enter to to open smart search to find City, County, Subdivision, MLS# and more"
          type="button"
          class="search-select-input text-dark form-control border py-2 text-left"
        >
          <div v-if="omni != null && omni != ''">
            {{ toTitleCase(omni) }}
          </div>
          <div v-else class="search-select-placeholder">{{ preview }}</div>
        </button>
        <span
          v-if="omni != null && omni != ''"
          @click="clear"
          @keyup.enter="clear"
          class="btn btn-danger cancel-button"
          role="link"
          aria-label="press enter to clear current smart search selection"
          tabindex="0"
        >
			X
        </span>
      </div>
      <div class="block w-full relative" ref="dropdown" v-show="isOpen">
        <input
          class="text-input"
          name="omni"
          v-model="omni"
          @keydown.esc="close"
          autocomplete="off"
          @keyup.enter.prevent="closeAndSelect"
          aria-label="Type to query available searches. Press tab to navigate options, or escape to cancel."
        />
      </div>
      <div
        class="search-select-dropdown"
        v-show="isOpen"
      >
        <div class="p-2">
          <slot />
          <input
            ref="search"
            placeholder="Type for available searches"
            aria-label="Type for available searches, press tab to select one"
            class="form-control border-accent"
            v-model="omni"
            @keydown.esc="close"
            autocomplete="off"
            id="omni-field"
            @keydown.enter.prevent="closeAndSelect"
          />
        </div>
        <div ref="options" v-if="options && options.length > 0" class="search-select-options border-bottom border-top">
          <div class="">
            <div
              v-for="category in options"
              :key="category.text"
            >
              <div v-if="category.children.length > 0" >
                <div class="search-select-category text-primary font-weight-bold bg-light py-1 px-3" >
                  {{ category.text }}
                </div>
                <div
                  v-for="child in category.children"
                  :key="child"
                  @click="select(child)"
                  @keyup.enter="select(child)"
                  :aria-label="'press enter to select ' + child"
                  role="link"
                  tabindex="0"
                  class="search-select-option py-1 px-3"
                >
                  {{ toTitleCase(child) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="omni && omni.length > 2" class="search-select-empty">
          No results found for <strong>{{ omni }}</strong>
        </div>
        <div class="action-buttons d-flex justify-content-between" >
          <span
            tabindex="0"
            @click="closeAndSelect"
            @keyup.enter="closeAndSelect"
            role="link"
            aria-label="press enter to close the menu and select current search phrase"
            class="d-inline-block p-2">search to view available options</span>
          <span
            tabindex="0"
            @click="clear"
            @keyup.enter="clear"
            role="link"
            aria-label="press enter to cancel current selection and close the smart search tool"
            class="text-danger p-2 cancel-link pointer">cancel</span>
        </div>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from "./OnClickOutside.vue";

export default {

  components: {
    "on-click-outside": OnClickOutside,
  },

  props: ["value", "fieldValue", "preview"],

  data() {
    return {
      isOpen: false,
      omni: "",
      baseUrl: "https://rets.kerigan.com/api/v2/smartfield",
      association: 'rafgc',
	    fields:'city|area|sub_area|subdivision|rets_listing_id|zip|full_address',
      omniTerms: [],
    };
  },

  mounted() {
    if (this.fieldValue !== "") {
      this.select(this.fieldValue);
    }
  },

  computed: {
    options () {
      if(this.omniTerms && this.omniTerms.length > 3){
        return this.filter(this.omni)
      } else {
        return []
      }
    }
  },

  methods: {
    getOptions (value){
      let vm = this

      fetch(vm.baseUrl + '?association=' + this.association + '&fields=' + this.fields + '&status=Active|Contingent|ActiveContingent|Active Contingent|Under Contract&exclude_areas=St. George Island|Carrabelle|Apalachicola|Eastpoint|Other Counties|Jackson County|Calhoun County|Holmes County|Washington County|East Franklin County')
        .then(res => res.json())
        .then(options => {
          vm.omniTerms = options
        })
    },

    open() {
      this.isOpen = true;
      this.getOptions();

      this.$nextTick(() => {
        this.focusInput()
      })
    },

    focusInput() {
      this.$refs.search.focus()
    },

    close() {
      if (!this.isOpen) return
      this.isOpen = false
    },

    closeAndSelect() {
      this.$emit("updated", {
          property: 'omni',
          value: this.omni
      })
      this.close()
    },

    clear() {
      this.omni = ""
      this.$emit("updated", {
          property: 'omni',
          value: ""
      })
      this.close()

      this.$nextTick(() => {
        this.$refs.button.focus()
      })
    },

    select(option) {
      console.log(option)
      this.omni = option
      this.$emit("updated", {
          property: 'omni',
          value: option
      })
      this.close()
    },

    toTitleCase(str) {
      if (!str) {
        return "";
      }
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    filter (text) {
      if(text && text.length < 3) { return [] }
      let options = []
      this.omniTerms.forEach(category => {
        options.push({
          text: category.text,
          children: category.children.filter(term => {
            return term != null && text != null && term.toLowerCase().includes(text.toLowerCase())
          })
        })
      })
      return options
    }
  },
};
</script>
<style scoped>
.search-select {
    position: relative;
}
.search-select-input {
    line-height: 1em;
    border-width: 1px;
}
.search-select-input:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.search-select-placeholder {
    color: #333;
}
.search-select.is-active .search-select-input {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.search-select-dropdown {
    position: absolute;
    right: 0;
    left: 0;
    top: -2px;
    background-color: #fff;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 50;
    border: 1px solid #dee2e6;
}
.search-select-search {
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 0.38rem 0.7rem;
    background-color: #fff;
    color: #2a2d2e;
    border-radius: 0;
    /* border: 1px solid #dee2e6; */
}
.search-select-search:focus {
    outline: 0;
}
.search-select-options {
    list-style: none;
    padding: 0.5rem 0;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 14rem;
}
.search-select-category {
    cursor: pointer;
    border-radius: 0.25rem;
    user-select: none;
}
.search-select-option {
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    border-radius: 0;
    user-select: none;
    margin: 0 2px;
}
.search-select-option:hover {
    background-color: #D00D4F;
    color: #fff;
}
.search-select-option.is-active,
.search-select-option.is-active:hover {
    background-color: #D00D4F;
    color: #fff;
}
.search-select-empty {
    padding: 0.5rem 0.75rem;
    color: #b8c2cc;
}
.cancel-button {
    position: absolute;
    right: 0;
    height: 37px;
    width: 37px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    line-height: 1em;
    cursor: pointer;
    font-weight: bold;
}
</style>
